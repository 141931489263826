import { useCallback, useEffect, useState } from 'react';
import { Toolbar, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  List,
  DataGrid,
  FunctionField,
  PreviewItem,
  InlineTextEdit,
  SwitchInput,
  FlexCenter,
} from '../../../components';
import { DeleteConfirmation } from '../../../components/modals/DeleteConfirmation';
import { useGetUsersData, useGlobalRefresh, useNotify } from '../../../hooks';
import { authPost } from '../../../lib';
import { uiActions } from '../../../state';

export function ApplicationTagsPage() {
  const { companyID } = useGetUsersData();

  const dispatch = useDispatch();
  const refresh = useGlobalRefresh();
  const notify = useNotify();

  const [editing, setEditing] = useState(true);
  const [showConfirmToggle, setShowConfirmToggle] = useState();
  const [currentLabel, setCurrentLabel] = useState(null);

  const url = `/companies/${companyID}/applicationTags`;

  const onSaveLabel = useCallback(
    async (payload) => {
      if (!payload.name.trim().length) {
        return;
      }

      const response = await authPost(url, payload);
      const { error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message:
              error.message || 'An error occurred while saving the label',
          }),
        );
      } else {
        notify('label saved successfully', 'success');
        refresh();
      }
    },
    [dispatch, refresh, url, notify],
  );

  const onClickToggle = useCallback(
    (record) => {
      if (!record.isEnabled) {
        setCurrentLabel(record);
        setShowConfirmToggle(true);
      } else {
        onSaveLabel(record);
      }
    },
    [onSaveLabel],
  );

  const handleConfirmToggleDisable = useCallback(() => {
    setShowConfirmToggle(false);
    onSaveLabel(currentLabel);
  }, [currentLabel, onSaveLabel]);

  useEffect(() => {
    //reset to editing mode after they save a value
    // (we want to stay in edit mode, but it needs to toggle in order to clear the value, so we immediately toggle back)
    if (!editing) {
      setEditing(true);
    }
  }, [editing]);

  return (
    <>
      <List
        baseUrl={url}
        resource='companyApplicationTags'
        bulkActionButtons={false}
        actions={
          <ListActions
            onSaveLabel={onSaveLabel}
            editing={editing}
            setEditing={setEditing}
          />
        }
      >
        <DataGrid>
          <FunctionField
            label='Label'
            align='left'
            render={(record) => {
              return (
                <PreviewItem
                  text={record.name}
                  editProps={{
                    value: record.name,
                    textInput: true,
                    inputProps: { maxLength: 50 },
                    onSave: (value) => onSaveLabel({ ...record, name: value }),
                  }}
                />
              );
            }}
          />
          <FunctionField
            align='right'
            render={(record) => {
              return (
                <SwitchInput
                  checked={record.isEnabled}
                  size='small'
                  onChange={() =>
                    onClickToggle({ ...record, isEnabled: !record.isEnabled })
                  }
                />
              );
            }}
          />
        </DataGrid>
      </List>
      {showConfirmToggle && (
        <DeleteConfirmation
          title='Disable Label'
          text='Are you sure you want to disable this label?  This label will be removed from all applications.'
          btnText='Confirm'
          handleClose={() => setShowConfirmToggle(false)}
          handleDelete={handleConfirmToggleDisable}
        />
      )}
    </>
  );
}

function ListActions({ onSaveLabel, setEditing, editing }) {
  return (
    <Toolbar sx={{ justifyContent: 'flex-end' }}>
      <FlexCenter width={500}>
        <Typography variant='description' whiteSpace='nowrap' mr={1}>
          Add a new label:
        </Typography>
        <InlineTextEdit
          setEditing={setEditing}
          editing={editing}
          onSave={(value) => onSaveLabel({ isEnabled: true, name: value })}
          value={''}
          inputProps={{ maxLength: 50 }}
          autoFocus={false}
        />
      </FlexCenter>
    </Toolbar>
  );
}
