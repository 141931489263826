import React, { useState, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import { Toolbar, Typography, Button, Box } from '@mui/material';
import { clsx } from 'clsx';
import {
  CaseTagIcon,
  List,
  DataGrid,
  FunctionField,
  TextField,
  SwitchInput,
} from '../../../components';
import { DeleteConfirmation } from '../../../components/modals/DeleteConfirmation';
import { CompanyCaseTagForm } from '../../../components/modals/CompanyCaseTagForm';
import FilterOption from '../../main/ListViews/filters/FilterOption';
import { AddIcon, EditIcon } from '../../../themes';
import { useStyles } from './companyPayersPage.styles';
import { settingActions, systemPersistSelectors } from '../../../state';
import { useGetUsersData, useGlobalRefresh } from '../../../hooks';
import { getResidentTextForCompanyType } from '../../../lib';

export default function CompanyTagsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const refresh = useGlobalRefresh();
  const states = useSelector(systemPersistSelectors.states);
  const [openAddTag, setOpenAddTag] = useState(false);
  const [openEditTag, setOpenEditTag] = useState(false);
  const [showConfirmToggle, setShowConfirmToggle] = useState(false);
  const [toggleValues, setToggleValues] = useState(null);
  const [editValues, setEditValues] = useStateWithCallbackInstant(null, (tag) =>
    setOpenEditTag(!!tag),
  );
  const { companyID, companyType } = useGetUsersData();

  const handleToggleTagEnabled = useCallback(
    async (values) => {
      const { data } = await dispatch(
        settingActions.postCompanyCaseTag(values),
      );
      if (data) {
        refresh();
      }
    },
    [dispatch, refresh],
  );

  const onClickToggle = useCallback(
    (id, isEnabled) => {
      if (!isEnabled) {
        setToggleValues({ id, isEnabled });
        setShowConfirmToggle(true);
      } else {
        handleToggleTagEnabled({ id, isEnabled });
      }
    },
    [handleToggleTagEnabled],
  );
  const handleConfirmToggleDisable = useCallback(() => {
    setShowConfirmToggle(false);
    handleToggleTagEnabled(toggleValues);
  }, [toggleValues, handleToggleTagEnabled]);

  const handleChangeStates = useCallback(
    async (id, stateIDs) => {
      const data = await dispatch(
        settingActions.postCompanyCaseTag({ id, stateIDs }),
      );
      if (data) {
        refresh();
      }
    },
    [dispatch, refresh],
  );
  return (
    <Fragment>
      <List
        baseUrl={`/companies/${companyID}/caseTags`}
        resource='companyCaseTags'
        hideShadow
        bulkActionButtons={false}
        className={classes.listContent}
        actions={
          <Fragment>
            <Toolbar className={classes.actions} disableGutters>
              <div className={classes.listHeader}>
                <Typography className={classes.title} component='div'>
                  Tags
                </Typography>
                <Button
                  color='primary'
                  variant='contained'
                  startIcon={<AddIcon />}
                  onClick={() => setOpenAddTag(true)}
                >
                  Tag
                </Button>
              </div>
            </Toolbar>
          </Fragment>
        }
        orderBy='name'
        order='asc'
      >
        <DataGrid>
          <FunctionField
            label='Name'
            cellStyle={{ paddingLeft: 0, width: 250 }}
            disablePadding
            align='left'
            render={(record) => {
              const { name, isOverrideIcon } = record;
              return (
                <span className={classes.flexRow}>
                  <Box component='span' marginRight={0.5}>
                    <CaseTagIcon caseTag={record} />
                  </Box>
                  {name}
                  {
                    //the base ones are not editable
                    isOverrideIcon && (
                      <EditIcon
                        className={clsx(
                          classes.editIcon,
                          classes.iconMarginLeft,
                        )}
                        onClick={() => {
                          setEditValues(record);
                        }}
                      />
                    )
                  }
                </span>
              );
            }}
          />
          <TextField
            sortable={false}
            source='typeDisplay'
            label='Category'
            cellStyle={{ width: 200 }}
          />
          <FunctionField
            label='State'
            sortable={false}
            render={(record) => {
              const { stateIDs = [], id } = record;
              return (
                <FilterOption
                  menuItems={states}
                  selectedMenuItems={states.filter((s) =>
                    (stateIDs || []).some((i) => i === s.id),
                  )}
                  label={''}
                  onChange={(values) => handleChangeStates(id, values)}
                  multiple
                  searchPlaceholder={'Find states'}
                  groupedOptionsLabel={'All states'}
                  customButtonStyles={classes.customDropdownButton}
                />
              );
            }}
          />

          <FunctionField
            align='right'
            cellStyle={{ paddingRight: 0 }}
            sortable={false}
            render={(record) => {
              const { isEnabled, id } = record;
              return (
                <SwitchInput
                  checked={isEnabled}
                  size='small'
                  onChange={() => onClickToggle(id, !isEnabled)}
                />
              );
            }}
          />
        </DataGrid>
      </List>
      {openAddTag && (
        <CompanyCaseTagForm
          open={openAddTag}
          handleClose={() => setOpenAddTag(false)}
        />
      )}
      {openEditTag && (
        <CompanyCaseTagForm
          open={openEditTag}
          handleClose={() => setEditValues(null)}
          editValues={editValues}
        />
      )}
      {showConfirmToggle && (
        <DeleteConfirmation
          title='Disable Tag'
          text={`Are you sure you want to disable this tag?  This tag will be turned off for all ${getResidentTextForCompanyType(
            companyType,
          )}s.`}
          btnText='Confirm'
          handleClose={() => setShowConfirmToggle(false)}
          handleDelete={handleConfirmToggleDisable}
        />
      )}
    </Fragment>
  );
}
