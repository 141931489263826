import makeStyles from '@mui/styles/makeStyles';
import { styled, Typography } from '@mui/material';

export const useStyles = makeStyles((theme) => ({
  dataGridRoot: {
    border: 'none',
    overflow: 'unset',
    '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-row:last-child': {
      '& .MuiDataGrid-cell': {
        borderBottom: 'none',
      },
    },
    '& .MuiDataGrid-row.Mui-hovered': {
      backgroundColor: '#F5F6FE',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: '#F5F6FE',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: 'text.secondary',
      whiteSpace: 'normal',
      lineHeight: 1.3,
    },
    '& .MuiDataGrid-cell': {
      whiteSpace: 'normal !important',
    },
  },
  dataGridMain: {
    overflow: 'unset',
  },
  dataGridColumnHeaders: {
    background: '#fff',
    borderRadius: 0,
    position: 'sticky',
    top: 162,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      top: 196,
    },
  },
  dataGridVirtualScroller: {
    minHeight: 80,
  },
  dataGridFooterContainer: {
    background: '#fff',
    bottom: 0,
    position: 'sticky',
    zIndex: 4,
  },
  residentNameField: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 132,
    fontWeight: 500,
    '& svg': {
      height: 16,
      marginLeft: 8,
    },
  },
  errorText: {
    color: '#f44336',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  statusChip: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    // height: 24,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

export const TextWithOverflow = styled(Typography)(() => ({
  overflow: 'hidden',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  textOverflow: 'ellipsis',
}));
