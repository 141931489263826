import React, { useCallback, useState, useEffect } from 'react';
import { string, object, array } from 'yup';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal } from './FormModal';
import { SelectInput, TextInput, AutocompleteInput } from '../common/inputs';
import { useGlobalRefresh, useGetUsersData } from '../../hooks';
import {
  resourceActions,
  facilitySelectors,
  systemPersistSelectors,
} from '../../state';
import {
  validateOnSubmit,
  convertNullFieldsToEmptyString,
  authGet,
} from '../../lib';
import { sharedFormStyles } from './sharedStyles';

const useStyles = makeStyles((theme) => ({ ...sharedFormStyles(theme) }));

export function CompanyPayerForm({ open, handleClose, editId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const refresh = useGlobalRefresh();
  const payerTypes = useSelector(systemPersistSelectors.payerTypes);
  const facilities = useSelector(facilitySelectors.facilityOptions);
  const { companyID } = useGetUsersData();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    name: '',
    longName: '',
    type: '',
    facilityIDs: [],
  });

  const [title, setTitle] = useState('');

  useEffect(() => {
    (async function () {
      if (editId) {
        const response = await authGet(
          `/companies/${companyID}/payers/${editId}`,
        );
        const { data, error } = response;
        if (error) {
          return;
        }
        const formatted = convertNullFieldsToEmptyString(data);
        setState((s) => ({ ...s, ...formatted }));
        setTitle(data.longName);
      }
    })();
  }, [dispatch, editId, companyID]);

  const onSave = useCallback(() => {
    return validateOnSubmit({ values: state, schema, setErrors })
      .then(async () => {
        setSubmitting(true);
        const { name, longName, ...rest } = state;
        const response = await dispatch(
          resourceActions.postResource({
            baseUrl: `/companies/${companyID}/payers`,
            payload: {
              name,
              longName: !!editId ? longName : name, //default the longName to name on create
              ...rest,
              id: editId,
            },
          }),
        );
        setSubmitting(false);
        const { data } = response;
        if (data) {
          refresh();
          handleClose();
        }
      })
      .catch((e) => console.error('error ', e));
  }, [dispatch, editId, handleClose, refresh, state, companyID]);

  const handleDelete = useCallback(async () => {
    setSubmitting(true);
    const response = await dispatch(
      resourceActions.deleteResource({
        baseUrl: `/companies/${companyID}/payers`,
        id: editId,
      }),
    );
    setSubmitting(false);
    const { error } = response;
    if (!error) {
      refresh();
      handleClose();
    }
  }, [dispatch, editId, handleClose, refresh, companyID]);

  const onChange = useCallback((event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState((curState) => ({ ...curState, [name]: value }));
  }, []);

  return (
    <FormModal
      handleClose={handleClose}
      open={open}
      title={editId ? title : 'Add company payer'}
      onSave={onSave}
      isEdit={!!editId}
      handleDelete={handleDelete}
      submitting={submitting}
    >
      <div className={classes.formPadding}>
        <Grid container className={classes.grid} spacing={4}>
          <Grid item xs={6} className={classes.gridModalItem}>
            <TextInput
              fullWidth
              className={classes.inputRow}
              value={state.name}
              name='name'
              onChange={onChange}
              label='Short name'
              schema={schema}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridModalItem}>
            <SelectInput
              fullWidth
              value={state.type}
              className={classes.inputRow}
              name='type'
              onChange={onChange}
              label='Payer type'
              menuItems={[...payerTypes].sort((v1, v2) =>
                v1.name.localeCompare(v2.name),
              )}
              schema={schema}
              error={errors.type}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridModalItem}>
            <AutocompleteInput
              fullWidth
              multiple
              textFieldProps={{
                label:
                  'Applicable facilities (Defaults to all if none selected)',
                onChange: onChange,
              }}
              autocompleteProps={{
                className: classes.inputRow,
                options: facilities,
                value: state.facilityIDs,
                name: 'facilityIDs',
              }}
              schema={schema}
              error={errors.facilityIDs}
            />
          </Grid>
        </Grid>
      </div>
    </FormModal>
  );
}

const schema = object().shape({
  name: string('This value must be a string').required(
    'Payer name is required',
  ),
  type: string('This value must be a string').required(
    'Payer type is required',
  ),
  facilityIDs: array(string())
    .typeError('This field must be an array')
    .nullable(),
});
