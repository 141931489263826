import { useState, useEffect } from 'react';
import { get, set } from 'idb-keyval';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../state';
import { PersistedDataKeyType } from '../lib';

/**
 * use IdbKeyVal storage for data that needs to persist even after the user logs out,
 * since we clear out the regular redux store - even the persisted one - on logout.
 * Use this instead of localStorage b/c localStorage is not supported on the PWA
 *
 * b/c this is async, the value may not be set immediately -
 * you can check that it has a value / or that it equals the default value before reading it
 * (default value will only be set after the data loads, if its empty)
 *
 * use this hook if the persisted data will only be  used in one component, similar to a regular useState.
 * if the persisted data needs to be more globally accessible, use the helper one below `useIdbKeyValPersistedSelector` together with redux
 * @param {PersistedDataKeyType} key
 * @param {*} defaultValue
 * @returns
 */
export const usePersistedState = (
  key: PersistedDataKeyType,
  defaultValue: any,
) => {
  const [value, setValue] = useState<any>();

  //set the initial state async (pull from storage, or use default)
  useEffect(() => {
    get(key).then((data) => setValue(data || defaultValue));
  }, [key, defaultValue]);

  //saving updated value
  useEffect(() => {
    if (value || value === defaultValue) {
      set(key, value); //this also returns a promise
    }
  }, [key, value, defaultValue]);

  return [value, setValue];
};

//Use this hook when you need to persist data that will be read/written from multiple places
//It works together with a redux selector and setter (action)
//This hook should only be called once for each key, in a 'parent' level component to any 'child' components that may access the data.
//All components  that need to access the data should read/write directly to redux using regular selectors and actions.
//This hook will handle persisting the data to IdbKeyVal, and initializing redux with the persisted data.
// NOTE: 'undefined' will not be considered valid as a `defaultValue`,
// this hook assumes undefined is an uninitialized state. (and should be the default for the *redux state* to indicate this)
export function usePersistedGlobalStateEffect(
  reduxSelector: (state: State) => any, //this is the selector that all components will use to read the data
  reduxSetter: (val: any) => {}, //this is the redux action (setter) that all components will use to write the data
  key: PersistedDataKeyType,
  defaultValue: any,
) {
  const dispatch = useDispatch();
  const reduxValue = useSelector(reduxSelector);

  const [persistedValue, setPersistedValue] = usePersistedState(
    key,
    defaultValue,
  );

  //once the persisted value loads, initialize the value in redux
  useEffect(() => {
    if (reduxValue === undefined && persistedValue !== undefined) {
      dispatch(reduxSetter(persistedValue));
    }
  }, [reduxValue, persistedValue, dispatch, reduxSetter]);

  //whenever the redux value is updated, update the persisted value as well:
  useEffect(() => {
    if (reduxValue !== undefined) {
      setPersistedValue(reduxValue);
    }
  }, [reduxValue, setPersistedValue]);
}
