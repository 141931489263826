import { NoCaseId } from './constants';
import { CaseIdType } from './types';

export const IS_NOT_PROD = process.env.REACT_APP_ENV !== 'production';

export function isCaseIdValid(caseId: CaseIdType) {
  return caseId !== NoCaseId;
}

export function safeIncrement(obj: Record<any, number>, key: any) {
  if (!obj[key]) {
    obj[key] = 1;
  } else {
    obj[key] += 1;
  }
}
