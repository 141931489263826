import { authGet, authPost } from '../../lib';
import { Dispatch, GetState, uiActions } from '..';
import { companies } from './state';

const { actions } = companies;

export const companyActions = {
  ...actions,
  getCompanies() {
    return async (dispatch: Dispatch, getState: GetState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authGet([
        `/companies/${companyID}/allowedCompanies`,
      ]);
      const { data, error } = response;
      if (error) {
        return;
      }
      dispatch(actions.setCompanies(data));
      dispatch(actions.setHasNoAllowedCompanies(data.length === 0));
    };
  },
  saveCompanyIntegrationMapping(payload: any, integrationId: number | string) {
    return async (dispatch: Dispatch, getState: GetState) => {
      const companyID = getState().authPersist.data?.companyID;
      dispatch(uiActions.setUILoading(true));
      const response = await authPost(
        [`companies/${companyID}/mappings`, { integrationId }],
        payload,
      );
      const { error, data } = response;
      dispatch(uiActions.setUILoading(false));
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      dispatch(uiActions.showNotification({ message: 'Changes saved' }));
      return { data };
    };
  },
  getCompanyPayers() {
    return async (dispatch: Dispatch, getState: GetState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authGet([`/companies/${companyID}/payers/basic`]);
      const { data, error } = response;
      if (error) {
        return;
      }
      dispatch(
        actions.setCompanyPayers({
          loading: false,
          hasNoPayers: (data || []).length === 0,
          payers: data,
        }),
      );
    };
  },
  getCompanyIntegrations() {
    return async () => {
      const response = await authGet([`/saas/integrations/basic`]);
      const { data, error } = response;
      if (error) {
        return { error };
      }
      return { data };
    };
  },
  getResidentInternalIntegrationSettings(residentIntegrationType: any) {
    return async (dispatch: Dispatch, getState: GetState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authGet([
        `/companies/${companyID}/internal/integration-settings`,
        { integrationType: residentIntegrationType },
      ]);
      const { data, error } = response;
      if (error) {
        return { error };
      }

      //data is a list of settings for diff integrations, but b/c we already filtered for our type, just get the first one
      dispatch(
        companyActions.setResidentInternalIntegrationSettings(data?.[0]),
      );
    };
  },
  getAllInternalIntegrationSettings() {
    return async (dispatch: Dispatch, getState: GetState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authGet(
        `/companies/${companyID}/internal/integration-settings`,
      );
      const { data, error } = response;
      if (error) {
        return { error };
      }

      dispatch(companyActions.setAllInternalIntegrationSettings(data));
    };
  },
  getInternalIntegrationsBasic() {
    return async (dispatch: Dispatch, getState: GetState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authGet(
        `/companies/${companyID}/internal/integrations/basic`,
      );
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      return { data };
    };
  },
  getVendorData({ forceRefresh, type, companyIntegrationID }: any) {
    return async (dispatch: Dispatch) => {
      const response = await authGet([
        'companies/vendor-data',
        { forceRefresh, type, companyIntegrationID },
      ]);
      const { data, error } = response;
      if (error) {
        dispatch(
          uiActions.showError({
            message: error.message || 'Something went wrong',
          }),
        );
        return { error };
      }
      return { data };
    };
  },

  getCompanyRegions() {
    return async (_: Dispatch, getState: GetState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authGet(`companies/${companyID}/regions`);
      return response;
    };
  },

  getCompanyRegionsBasic() {
    return async (dispatch: Dispatch, getState: GetState) => {
      const companyID = getState().authPersist.data?.companyID;
      const response = await authGet(`companies/${companyID}/regions/basic`);
      const { data, error } = response;
      if (data) {
        dispatch(
          companyActions.setRegions(
            data.map((r: any) => ({ id: r.id, name: r.regionName })),
          ),
        );
      } else {
        dispatch(
          uiActions.showError({
            message: error?.message || 'Regions cannot be loaded',
          }),
        );
        dispatch(companyActions.setRegions([]));
      }
      return response;
    };
  },
};
