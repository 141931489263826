import makeStyles from '@mui/styles/makeStyles';
import {
  PREVIEW_WIDTH,
  SIDEBAR_RIGHT_WIDTH,
  SITE_HEADER_HEIGHT,
} from '../../lib';

export const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    height: `calc(100vh - ${SITE_HEADER_HEIGHT}px)`,
    overflow: 'auto',
    background: '#fff',
    transition: 'all 300ms linear',
    top: SITE_HEADER_HEIGHT,
    width: PREVIEW_WIDTH,
    boxShadow: '0 24px 32px 0 rgba(0,0,0,0.08), 0 24px 24px 0 rgba(0,0,0,0.12)',
    padding: '0 24px 24px',
    zIndex: 4,
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      overflowWrap: 'break-word',
      overflow: 'hidden',
      maxWidth: `calc(${PREVIEW_WIDTH}px - 104px)`,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&.open': {
      right: SIDEBAR_RIGHT_WIDTH,
    },
    '&.closed': {
      right: -PREVIEW_WIDTH,
    },
    '& button': {
      minWidth: 'unset',
    },
  },
  overlayHeader: {
    paddingTop: 24,
    position: 'sticky',
    top: 0,
    background: '#fff',
    zIndex: 2,
  },
  overlayHeaderFlexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  overlayClose: {
    '& button': {
      marginLeft: 8,
      marginRight: -8,
    },
    '& svg': {
      fill: theme.palette.text.secondary,
    },
  },
  overlayTabs: {
    margin: '0 -24px',
    padding: '0 20px',
    borderBottom: '1px solid #E5E7F5',
    '& .MuiTabs-flexContainer': {
      gap: 16,
    },
    '& button': {
      fontSize: 14,
      padding: '0 4px',
      textTransform: 'none',
    },
  },
  header: {
    paddingBottom: 4,
    position: 'sticky',
    top: (props) => (props.isOverlayOpen ? 105 : 186),
    paddingTop: 16,
    background: '#fff',
    zIndex: 2,
  },
  headerFirstRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginRight: 12,
  },
  notesActions: {
    display: 'flex',
    color: theme.palette.text.link,
    fontSize: 14,
    fontWeight: 500,
    '& button': {
      marginLeft: 12,
      color: 'inherit',
      textTransform: 'unset',
      fontWeight: 600,
      background: '#E5E7F5',
      height: 28,
      width: 28,
      minWidth: 28,
      padding: 0,
    },
    '& svg': {
      fontSize: 18,
      fill: '#000',
    },
  },
  filterAmount: {
    background: theme.palette.primary.main,
    height: 12,
    width: 12,
    borderRadius: '100%',
    position: 'absolute',
    color: '#fff',
    fontSize: 10,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    right: 2,
    top: 4,
    zIndex: 1,
  },
  headerFourthRow: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  clearFilters: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.text.link,
    marginLeft: 16,
    textTransform: 'unset',
  },
  emptyState: {
    textAlign: 'center',
    marginTop: 80,
    '& span': {
      display: 'block',
      marginTop: 16,
      '&:last-child': {
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginTop: 8,
      },
    },
  },
}));
