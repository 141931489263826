import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import { Toolbar, Typography, Button } from '@mui/material';
import {
  List,
  DataGrid,
  FunctionField,
  TextField,
  ListFiltersActions,
} from '../../../components';
import { CompanyPayerForm } from '../../../components/modals/CompanyPayerForm';
import { AddIcon, EditIcon } from '../../../themes';
import { useStyles } from './companyPayersPage.styles';
import { useGetQueryParams, useGetUsersData } from '../../../hooks';
import { facilityActions, systemPersistSelectors } from '../../../state';
import { getFiltersFromDropdownList } from '../../../lib';

export function CompanyPayersPage({ facilityId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openAddPayer, setOpenAddPayer] = useState(false);
  const [openEditPayer, setOpenEditPayer] = useState(false);
  const [editId, setEditId] = useStateWithCallbackInstant(null, (payerId) =>
    setOpenEditPayer(!!payerId),
  );
  const { search, 'payer-type': payerTypes } = useGetQueryParams();
  const { companyID } = useGetUsersData();

  useEffect(() => {
    dispatch(facilityActions.getFacilities());
  }, [dispatch]);

  return (
    <Fragment>
      <List
        baseUrl={`/companies/${companyID}/payers`}
        resource='companyPayers'
        filter={{ text: search, payerTypes }}
        bulkActionButtons={false}
        actions={<ListActions setOpenAddPayer={setOpenAddPayer} />}
        orderBy='name'
        order='asc'
      >
        <DataGrid>
          <TextField source='name' label='Name' />
          <TextField source='payerTypeDisplay' label='Type' sortBy='type' />
          <TextField
            source='facilitiesDisplay'
            label='Facilities'
            sortable={false}
          />

          <FunctionField
            align='right'
            sortable={false}
            render={(record) => {
              const { id } = record;
              return (
                <EditIcon
                  className={classes.editIcon}
                  onClick={() => setEditId(id)}
                />
              );
            }}
          />
        </DataGrid>
      </List>
      {openAddPayer && (
        <CompanyPayerForm
          facilityId={facilityId}
          open={openAddPayer}
          handleClose={() => setOpenAddPayer(false)}
        />
      )}
      {openEditPayer && (
        <CompanyPayerForm
          open={openEditPayer}
          handleClose={() => setEditId(null)}
          facilityId={facilityId}
          editId={editId}
        />
      )}
    </Fragment>
  );
}

function ListActions({ setOpenAddPayer }) {
  const classes = useStyles();
  const payerTypes = useSelector(systemPersistSelectors.payerTypes);
  const [payerTypesFilter, setPayerTypesFilter] = useState('');

  useEffect(() => {
    setPayerTypesFilter(getFiltersFromDropdownList(payerTypes));
  }, [payerTypes]);

  return (
    <Fragment>
      <Toolbar className={classes.actions} disableGutters>
        <div className={classes.listHeader}>
          <Typography className={classes.title} component='div'>
            Payers
          </Typography>
          <Button
            color='primary'
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => setOpenAddPayer(true)}
          >
            Payer
          </Button>
        </div>
      </Toolbar>

      <ListFiltersActions
        className={classes.listFiltersActions}
        hasSearchField
        hideToggleShowBtn
        searchAndFilterInline
        filtersProps={{
          hasMultipleFilters: true,
          filters: [
            {
              filter: payerTypesFilter,
              queryParam: 'payer-type',
              label: 'Payer type',
            },
          ],
        }}
      ></ListFiltersActions>
    </Fragment>
  );
}
