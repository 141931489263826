import {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';

import {
  styled,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { CustomTooltip, FlexBetween, Loader } from '../ui';
import { ColoredIcon } from '../common';
import { DeleteConfirmation } from '../modals/DeleteConfirmation';
import { ArrowBackIcon, CloseIcon, DeleteOutlinedIcon } from '../../themes';

import { SITE_HEADER_HEIGHT, IconType } from '../../lib';

const PADDING_X = '24px';

const SIZES = {
  extraSmall: '444px',
  small: '600px',
  medium: '900px',
  large: '1200px',
};

type Props = HeaderProps &
  FooterProps & {
    size?: keyof typeof SIZES;
    pageHeaderHeight?: number;
    noSubmitOnEnter?: boolean;
    hideFooter?: boolean;
    children: ReactElement | ReactElement[] | string;
  };
export function FormOverlay(props: Props) {
  const {
    // general settings
    title,
    size = 'small',
    pageHeaderHeight = 0,
    isEdit,
    submitting,
    errorMessage,
    noSubmitOnEnter = false,
    hideFooter = false,

    // children
    children,

    // close functionality
    handleClose,

    // save functionality
    disableSave,
    saveButtonText = 'Save',
    onSave,
    additionalSaveButtonText,
    additionalOnSave,

    // delete functionality
    deleteTitle,
    deleteMsg,
    handleDelete,
    hideDeleteBtn = false,
    disableDeleteButtonReason,

    // navigation
    handleBack,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [showDivider, setShowDivider] = useState(false);

  // TODO not working right
  const scrollHandler = useCallback(() => {
    const scrollTop = containerRef.current?.scrollTop || 0;
    setShowDivider(scrollTop > 10);
  }, []);

  return (
    <OverlayContainer
      width={SIZES[size]}
      pageHeaderHeight={pageHeaderHeight + 36} // this height calculation might need to be tweaked to handle all situations
      ref={containerRef}
      onScroll={scrollHandler}
    >
      <Header
        title={title}
        isEdit={isEdit}
        submitting={submitting}
        handleBack={handleBack}
        handleClose={handleClose}
        // delete functionality
        deleteTitle={deleteTitle}
        deleteMsg={deleteMsg}
        handleDelete={handleDelete}
        hideDeleteBtn={hideDeleteBtn}
        disableDeleteButtonReason={disableDeleteButtonReason}
      />

      {showDivider && <Divider style={{ marginBottom: '12px' }} />}

      <Box>
        <form
          autoComplete='off'
          onSubmit={noSubmitOnEnter ? (e) => e.preventDefault() : undefined}
        >
          {children}
        </form>
      </Box>

      {!hideFooter && (
        <Footer
          submitting={submitting}
          errorMessage={errorMessage}
          handleClose={handleClose}
          // save functionality
          disableSave={disableSave}
          saveButtonText={saveButtonText}
          onSave={onSave}
          additionalSaveButtonText={additionalSaveButtonText}
          additionalOnSave={additionalOnSave}
        />
      )}
    </OverlayContainer>
  );
}

type HeaderProps = {
  title?: string;
  isEdit?: boolean;
  submitting?: boolean;
  handleClose?: () => void;

  // delete functionality
  deleteTitle?: string;
  deleteMsg?: string;
  handleDelete?: MouseEventHandler<HTMLButtonElement>;
  hideDeleteBtn?: boolean;
  disableDeleteButtonReason?: string;

  // navigation
  handleBack?: () => void;
};
const Header = (props: HeaderProps) => {
  const {
    deleteMsg,
    deleteTitle,
    disableDeleteButtonReason,
    handleBack,
    handleClose,
    handleDelete,
    hideDeleteBtn,
    isEdit,
    submitting,
    title,
  } = props;

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  return (
    <>
      <FlexBetween
        paddingY={2}
        sx={{
          // header section should be frozen
          position: 'sticky',
          top: 0,
          // these properties prevent scrolled elements from "showing through" the header:
          zIndex: 2,
          background: '#FFF',
        }}
      >
        <Box display='flex'>
          {handleBack && (
            <ToolbarButton Icon={ArrowBackIcon} action={handleBack} />
          )}
          {title && <Typography variant='h5'>{title}</Typography>}
        </Box>

        <Box display='flex'>
          {isEdit && !hideDeleteBtn && (
            <CustomTooltip title={disableDeleteButtonReason}>
              <ToolbarButton
                Icon={DeleteOutlinedIcon}
                action={() => setShowDeleteConfirm(true)}
              />
            </CustomTooltip>
          )}
          {handleClose && (
            <ToolbarButton Icon={CloseIcon} action={handleClose} />
          )}
        </Box>
      </FlexBetween>

      {showDeleteConfirm && (
        <DeleteConfirmation
          title={deleteTitle || title || ''}
          text={deleteMsg || ''}
          btnText='Yes, delete it'
          submitting={submitting}
          handleDelete={handleDelete!}
          handleClose={() => setShowDeleteConfirm(false)}
        />
      )}
    </>
  );
};

const ToolbarButton = ({
  Icon,
  action,
}: {
  Icon: IconType;
  action: () => void;
}) => (
  <IconButton onClick={action}>
    <ColoredIcon Icon={Icon} iconColor='action.primaryColor' />
  </IconButton>
);

type FooterProps = {
  submitting?: boolean;
  errorMessage?: string;
  handleClose?: () => void;

  // save functionality
  disableSave?: boolean;
  saveButtonText?: string;
  onSave?: () => void;
  additionalSaveButtonText?: string;
  additionalOnSave?: MouseEventHandler<HTMLButtonElement>;
};
const Footer = (props: FooterProps) => {
  const {
    submitting,
    errorMessage,
    handleClose,

    // save functionality
    disableSave,
    saveButtonText = 'Save',
    onSave,
    additionalSaveButtonText,
    additionalOnSave,
  } = props;

  return (
    <Box>
      <Divider sx={{ marginTop: 1.5, marginX: `-${PADDING_X}` }} />

      {submitting && <Loader />}

      <Box display='flex' margin={2} gap={2} justifyContent='right'>
        <Button onClick={handleClose} disabled={submitting}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={onSave}
          disabled={submitting || !!disableSave}
        >
          {saveButtonText}
        </Button>
        {additionalSaveButtonText && (
          <Button
            variant='contained'
            onClick={additionalOnSave}
            disabled={submitting || !!disableSave}
          >
            {additionalSaveButtonText}
          </Button>
        )}
      </Box>

      {errorMessage && (
        <Box justifyContent='right'>
          <Typography color='error.main'>{errorMessage}</Typography>
        </Box>
      )}
    </Box>
  );
};

interface CustomProps {
  pageHeaderHeight: number;
  width?: string;
}
const OverlayContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'pageHeaderHeight' && prop !== 'width',
})<CustomProps>(({ pageHeaderHeight, width }) => ({
  width: width,
  minWidth: width,
  marginTop: '-16px',
  paddingLeft: PADDING_X,
  backgroundColor: '#FFF',
  boxShadow: '-16px 0px 24px -18px rgba(0, 0, 0, 0.12)',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  height: `calc(100vh - ${pageHeaderHeight + SITE_HEADER_HEIGHT}px)`,
}));
