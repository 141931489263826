import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { systemPersistSelectors, uiActions, uiSelectors } from '../../../state';
import { useGetUsersData, useNotify } from '../../../hooks';
import { companySettingGroups, companyTypes } from '../../../lib';
import { SwitchInput } from '../../../components/common/inputs';

export function GeneralSettingsPage() {
  const dispatch = useDispatch();
  const notify = useNotify();

  const { companyID, companyType } = useGetUsersData();

  const companySettings = useSelector(uiSelectors.companySettings);
  const [companySettingTypes, setCompanySettingTypes] = useState({});

  const {
    applicationCompanySettingTypes = [],
    facilityCompanySettingTypes = [],
  } = useSelector(systemPersistSelectors.systemSettings, shallowEqual) || {};

  useEffect(() => {
    if (companyType) {
      const availSettingTypes =
        companyType === companyTypes.Facility
          ? facilityCompanySettingTypes
          : applicationCompanySettingTypes;

      const groupedCompanySettingTypes = {};
      Object.keys(companySettingGroups).forEach((groupTitle) => {
        const availSettingByGroup = availSettingTypes.filter((s) =>
          companySettingGroups[groupTitle].includes(s.id),
        );
        if (availSettingByGroup) {
          groupedCompanySettingTypes[groupTitle] = availSettingByGroup;
        }
      });

      setCompanySettingTypes(groupedCompanySettingTypes);
    }
  }, [
    applicationCompanySettingTypes,
    facilityCompanySettingTypes,
    companyType,
  ]);

  const handleChange = async (setting, value) => {
    dispatch(
      uiActions.updateCompanySetting(companyID, {
        ...setting,
        isActive: value,
      }),
    );
    notify('Changes saved');
  };

  return (
    <div>
      {Object.keys(companySettingTypes).map((settingGroupTitle) => (
        <div key={settingGroupTitle}>
          {/* TODO: commenting out for now pending EK decision on title names  */}
          {/* <p className={classes.boldField}>{settingGroupTitle}</p> */}
          {companySettingTypes[settingGroupTitle].map((settingType) => {
            const setting = companySettings?.find(
              (s) => s.settingType === settingType.id,
            );
            return (
              <div
                key={settingType.id}
                style={{ marginTop: 20, marginLeft: 20 }}
              >
                <SwitchInput
                  name={settingType.name}
                  checked={setting?.isActive || false}
                  disabled={companySettings === undefined} // disable when settings are not yet loaded
                  onChange={(e) =>
                    handleChange(
                      setting
                        ? setting
                        : {
                            settingType: settingType.id,
                            companyId: companyID,
                          },
                      e.target.checked,
                    )
                  }
                  label={settingType.name}
                />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
