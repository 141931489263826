import makeStyles from '@mui/styles/makeStyles';
import { SIDEBAR_RIGHT_WIDTH } from '../lib';

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: SIDEBAR_RIGHT_WIDTH,
  },
  drawerPaper: {
    width: SIDEBAR_RIGHT_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 96,
    paddingBottom: 48,
  },
  toggleOverlay: {
    color: theme.palette.text.secondary,
  },
  menuTooltip: {
    backgroundColor: theme.palette.primary.light,
    maxWidth: 264,
    padding: '8px 12px 12px',
  },
  menuTooltipArrow: {
    color: theme.palette.primary.light,
  },
}));
