import { Box, Typography } from '@mui/material';
import { WarningIcon } from '../../themes';

type WarningBannerProps = {
  title: string;
  content: string;
};

export function WarningBanner({
  title = 'Note:',
  content = '',
}: WarningBannerProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgba(254, 131, 26, .20)',
        p: 1,
        borderRadius: '10px',
        width: '100%',
        mb: 2,
        mx: 4,
      }}
    >
      <WarningIcon color='warning' sx={{ mr: 1.5 }} />
      <Typography
        sx={{
          fontSize: 13,
          textTransform: 'none',
        }}
      >
        <strong>{title}</strong> {content}
      </Typography>
    </Box>
  );
}
