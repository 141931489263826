import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetQueryParams } from '../../../hooks';
import {
  uiActions,
  caseActions,
  systemPersistSelectors,
  uiSelectors,
} from '../../../state';
import {
  QuickActionType,
  getNoteTypeFromView,
  getResidentsList,
} from '../../../lib';
import { NoteForm } from '../../modals/NoteForm';
import { TaskForm } from '../../modals/TaskForm';
import { ApplicationForm } from '../../modals/ApplicationForm';
import { PrintForm } from '../../modals/PrintForm';

export function QuickActionsModals() {
  // hooks
  const dispatch = useDispatch();
  const { view } = useGetQueryParams();

  // state
  const [caseData, setCaseData] = useState(null);
  const [caseApplicationDetails, setCaseApplicationDetails] = useState(null);
  const [peopleList, setPeopleList] = useState(null);

  const [printOpen, setPrintOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);
  const [tasksOpen, setTasksOpen] = useState(false);
  const [applicationOpen, setApplicationOpen] = useState(false);

  // selectors
  const { caseId, residentId, caseName, quickActionType } = useSelector(
    uiSelectors.quickAction,
  );
  const caseNoteTypes = useSelector(systemPersistSelectors.caseNoteTypes);

  const getResidentApplicationData = useCallback(async () => {
    const [caseApplicationDetails, caseData] = await Promise.all([
      dispatch(caseActions.getApplicationDetails(caseId)),

      // technically this second call is not necessary when on pages that already loaded the case overview
      // see: https://git.bitbean.com/medicaid/aidace/-/merge_requests/2280#note_163378
      dispatch(
        caseActions.getCaseOverview({
          caseId,
          isGlobal: false, //we don't want to save this data to redux since this is just for the quick action
        }),
      ),
    ]);
    setCaseApplicationDetails(caseApplicationDetails.data);
    setCaseData(caseData.data);
    setPeopleList(getResidentsList(caseData.data, true));
  }, [caseId, dispatch]);

  const handleModals = useCallback(
    async (type) => {
      switch (type) {
        case QuickActionType.Tasks:
          setTasksOpen(!tasksOpen);
          break;
        case QuickActionType.Print:
          setPrintOpen(!printOpen);
          break;
        case QuickActionType.Notes:
          setNotesOpen(!notesOpen);
          break;
        case QuickActionType.Application:
          if (!applicationOpen) {
            await getResidentApplicationData();
          }
          setApplicationOpen(!applicationOpen);
          break;
        default:
          // do nothing
          break;
      }
    },
    [
      applicationOpen,
      notesOpen,
      tasksOpen,
      printOpen,
      getResidentApplicationData,
    ],
  );

  useEffect(() => {
    if (quickActionType !== QuickActionType.None) {
      handleModals(quickActionType);
      dispatch(uiActions.setQuickActionType(QuickActionType.None));
    }
  }, [quickActionType, handleModals, dispatch]);

  return (
    <>
      {tasksOpen && (
        <TaskForm
          open={tasksOpen}
          handleClose={() => handleModals(QuickActionType.Tasks)}
          caseId={caseId}
          quickActionCaseName={caseName}
        />
      )}
      {applicationOpen && (
        <ApplicationForm
          open={true}
          handleClose={() => handleModals(QuickActionType.Application)}
          caseId={caseId}
          peopleList={peopleList}
          maritalStatus={caseData?.resident?.maritalStatus}
          applicationDetails={caseApplicationDetails}
          facilityState={caseData?.resident?.filingStateCode}
          facilityStateId={caseData?.resident?.filingStateID}
          useModal
        />
      )}
      {notesOpen && (
        <NoteForm
          open={true}
          handleClose={() => handleModals(QuickActionType.Notes)}
          categories={caseNoteTypes}
          apiURL={`/cases/${caseId}/notes`}
          noteType={getNoteTypeFromView(view)}
          quickActionCaseName={caseName}
          caseId={caseId}
        />
      )}
      {printOpen && (
        <PrintForm
          open={printOpen}
          handleClose={() => handleModals(QuickActionType.Print)}
          residentId={residentId}
          quickActionCaseName={caseName}
        />
      )}
    </>
  );
}
