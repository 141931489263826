import React, { ChangeEvent, memo, useCallback } from 'react';
import { useGetQueryParams, useUpdatePageQueryParams } from '../../../../hooks';

import { Switch, Typography } from '@mui/material';
import { FlexCenter } from '../../../../components';

function TasksAssignedToMeSwitch() {
  const { assignedToMe = 'false' } = useGetQueryParams();
  const updatePageQueryParams = useUpdatePageQueryParams();

  const onChangeAssignedToMe = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      updatePageQueryParams({ assignedToMe: e.target.checked }),
    [updatePageQueryParams],
  );

  const assignedToMeBool = assignedToMe === 'true';
  return (
    <FlexCenter>
      <Typography variant='label'>Tasks assigned to me</Typography>
      <Switch
        checked={assignedToMeBool}
        onChange={onChangeAssignedToMe}
        size='small'
      />
    </FlexCenter>
  );
}

export default memo(TasksAssignedToMeSwitch);
