import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'capitalize',
    fontSize: 28,
    fontWeight: 500,
    width: '100%',
    marginBottom: 25,
    marginTop: 24,
  },
  pageSmallTitle: {
    color: '#000000',
    fontSize: 16,
    fontWeight: 500,
  },
  detailsActions: {
    ...flex,
    flexWrap: 'Wrap',
    width: '100%',
    marginTop: 16,
  },
  divider: {
    margin: '-1px -24px',
    background: '#E5E7F5',
  },
  chip: {
    margin: 4,
  },
}));

const flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
