import { useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import { NoteForm } from '../../modals/NoteForm';
import {
  caseActions,
  caseSelectors,
  systemPersistSelectors,
  uiSelectors,
} from '../../../state';
import { useGetQueryParams, useViewVersionListener } from '../../../hooks';
import { Loader, RefreshPrompt } from '../..';
import NotesOverlayHeader from './NotesOverlayHeader';
import { useStyles } from '../overlays.styles';
import { getNoteTypeFromView, RefreshKeys } from '../../../lib';
import { NoteListItem } from '../../common/notesList/NotesList';

export function NotesOverlay({ containerId, headerRef }) {
  // hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    authors,
    noteSearch,
    'list-filters': queryListFilters,
    view,
  } = useGetQueryParams();

  // selectors
  const caseNoteTypes = useSelector(systemPersistSelectors.caseNoteTypes);
  const notes = useSelector(caseSelectors.caseNotes);
  const previewId = useSelector(uiSelectors.previewId);

  // state
  const [caseId, setCaseId] = useState();

  const notesViewVersion = useSelector((state) =>
    uiSelectors.viewVersion(state, RefreshKeys.Notes, caseId),
  );

  const [editId, setEditId] = useStateWithCallbackInstant(null, (noteId) =>
    setOpenEditNote(!!noteId),
  );
  const [expandedNoteId, setExpandedNoteId] = useState(null);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [hasMore, setHasMore] = useState(
    notes ? (notes.numberOfRows > 15 ? true : false) : false,
  );

  const [openEditNote, setOpenEditNote] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [showRefreshPrompt, setShowRefreshPrompt] = useState(false);

  useEffect(() => {
    if (id) {
      setCaseId(id);
    } else if (previewId) {
      setCaseId(previewId);
    }
  }, [id, previewId]);

  useEffect(() => {
    setHasMore(pageNumber <= Math.ceil(notes.numberOfRows / 15));
  }, [notes, pageNumber]);

  const handleLoadMore = useCallback(
    async (page) => {
      setPageNumber(page);
      dispatch(
        caseActions.getCaseNotes(
          caseId,
          noteSearch,
          queryListFilters,
          authors,
          page,
        ),
      );
    },
    [dispatch, caseId, noteSearch, authors, queryListFilters],
  );

  const refreshNotes = useCallback(async () => {
    setInitialDataLoaded(false);
    setShowRefreshPrompt(false);

    await handleLoadMore(1);

    setInitialDataLoaded(true);
  }, [handleLoadMore]);

  useEffect(() => {
    refreshNotes();
  }, [refreshNotes]);

  useViewVersionListener(
    () => setShowRefreshPrompt(true),
    notesViewVersion,
    caseId,
  );

  const noteFormProps = {
    categories: caseNoteTypes,
    apiURL: `/cases/${caseId}/notes`,
    refresh: refreshNotes, //when editing/adding directly from the notes lists, we automatically refresh and don't use the viewVersion
    //NOTE if design changes and notes can be edited while another piece of the screen can display them (i.e. activity in new design, list? etc),
    // then add a preRefreshCallback to the noteForm and use that here instead of this.  see how todosList handles 'triggeredInline'
    noteType: getNoteTypeFromView(view),
  };

  return (
    <>
      <NotesOverlayHeader
        caseId={caseId}
        noteFormProps={noteFormProps}
        isPreview={!!previewId}
        headerRef={headerRef}
      />

      {!initialDataLoaded ? (
        <Loader />
      ) : (
        <div>
          {showRefreshPrompt && <RefreshPrompt handleRefresh={refreshNotes} />}

          {notes &&
            notes.results &&
            (Object.values(notes.results).length > 0 ? (
              <InfiniteScroll
                dataLength={notes.results.length}
                hasMore={hasMore}
                next={() => handleLoadMore(pageNumber + 1)}
                loader={<Loader />}
                scrollableTarget={containerId}
              >
                {Object.values(notes.results).map((note) => (
                  <NoteListItem
                    key={note.id}
                    note={note}
                    setEditId={setEditId}
                    expandedNoteId={expandedNoteId}
                    setExpandedNoteId={setExpandedNoteId}
                    authorInfoMultiLine
                    variant={'caption'}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <div className={classes.emptyState}>
                <img src='/images/no-notes.svg' alt='' height='96' />
                <span>No results found</span>
                <span>Try adjusting your search or filters</span>
              </div>
            ))}
        </div>
      )}

      {openEditNote && (
        <NoteForm
          open={true}
          handleClose={() => setEditId(null)}
          editId={editId}
          {...noteFormProps}
        />
      )}
    </>
  );
}
