import { companyTypes, PermissionClaims } from '../../lib';
import { State } from '../types';

export const authPersistSelectors = {
  data: (state: State) => state.authPersist.data,
  isApplicationCompany: (state: State) =>
    state.authPersist.data.companyType === companyTypes.Application,
  hasCompanyListEditPermission: (state: State) =>
    state.authPersist.data.PermissionClaim?.includes(
      PermissionClaims.CompanyListEditClaim,
    ),
};
