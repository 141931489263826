import { MouseEventHandler } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { PaperComponent } from './PaperComponent';
import { Loader } from '../ui';

type Props = {
  title: string;
  text: string;
  btnText?: string;
  submitting?: boolean;
  handleDelete: MouseEventHandler<HTMLButtonElement>;
  handleClose: MouseEventHandler<HTMLButtonElement>;
};
export function DeleteConfirmation({
  text,
  title,
  btnText = 'Delete',
  submitting,
  handleClose,
  handleDelete,
}: Props) {
  return (
    <Dialog
      open={true}
      aria-labelledby='form-dialog-title'
      fullWidth
      disableScrollLock
      PaperComponent={PaperComponent}
      // @ts-ignore // it works like this though TS is complaining
      PaperProps={{ size: 'xs' }}
    >
      <DialogTitle
        style={{ cursor: 'move', width: '100%' }}
        id='draggable-dialog'
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        {submitting && <Loader />}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color='error' variant='contained' onClick={handleDelete}>
            {btnText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
