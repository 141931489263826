import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { uiActions } from '../state';
import { CaseIdType, NoCaseId, RefreshKeyType } from '../lib';

export function useIncrementViewVersion(
  viewName: RefreshKeyType,
  caseId?: CaseIdType,
) {
  const dispatch = useDispatch();
  return useCallback(
    (doRefresh = true) =>
      doRefresh &&
      dispatch(
        uiActions.incrementViewVersion({
          viewName,
          caseId: caseId || NoCaseId,
        }),
      ),
    [viewName, dispatch, caseId],
  );
}
