import { useCallback, useEffect, useRef } from 'react';

// Delayed listener and regular listener:
// Use the listener when you need different behavior for the refresh vs the initial load.
// The refresh listener handles the logic for ignoring the initial load
// Initial load is determined by the **first valid render**:
//    If ‘isNotCaseSpecific = true’, then the caseId is not required, in which case it would just skip the first render
//    Else ‘isNotCaseSpecific = false’ (default) then the a render is considered valid only when there is a valid caseId
// Delayed refresh listener has a 2 sec timeout to wait for elastic to be updated before loading the data.

export function useViewVersionListener(
  loadDataFunction,
  viewVersion,
  caseId,
  isNotCaseSpecific = false,
  useDelay = false,
) {
  const refreshData = useCallback(() => {
    if (useDelay) {
      setTimeout(() => {
        // reload data  after elastic refreshes
        loadDataFunction();
      }, 2000);
    } else {
      loadDataFunction();
    }

    // we are only specifying viewVersion and useDelay as dependencies because that is the only condition that should trigger the refresh here
    // regular reloads should be handled by the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewVersion, useDelay]);

  const earlyExit = !isNotCaseSpecific && !caseId;

  useIgnoreInitialLoadEffect(refreshData, earlyExit, caseId);
}

export function useDelayedViewVersionListener(
  loadDataFunction,
  viewVersion,
  caseId,
  isNotCaseSpecific = false,
) {
  useViewVersionListener(
    loadDataFunction,
    viewVersion,
    caseId,
    isNotCaseSpecific,
    true,
  );
}

function useIgnoreInitialLoadEffect(
  callback,
  earlyExit = undefined, //if true the callback will not run
  loadTarget, //like the caseID, when this changes, the 'isInitialLoad' gets reset
) {
  const isInitialLoad = useRef(true);

  useEffect(() => {
    isInitialLoad.current = true;
  }, [loadTarget]);

  useEffect(() => {
    if (earlyExit) {
      return;
    }

    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      return;
    }

    callback();
  }, [callback, earlyExit]);
}
