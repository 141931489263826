import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  pageSection: {
    marginBottom: 40,
  },
  pageSectionTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 32,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  pageSectionSubtitle: {
    marginTop: 8,
    marginBottom: 24,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: 600,
      maxWidth: '100%',
    },
  },
  linkText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary.light,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  // webhooks
  eventsTable: {
    width: 896,
    maxWidth: '100%',
  },
  eventsTableTitle: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    borderBottom: '1px solid #D5D8EC',
    marginBottom: 0,
    paddingBottom: 12,
  },
  eventsTableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #D5D8EC',
  },
  eventsTableRowTitle: {
    fontWeight: 600,
  },
}));
