import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { TextInput } from '../../../components';
import { FormModal } from '../../../components/modals/FormModal';
import { alertActions } from '../../../state';
import { validateOnSubmit } from '../../../lib';
import { useStyles } from './alerts.styles';

const schema = object().shape({
  reason: string().required(),
});

export default function DismissReasonModal({
  handleClose,
  open,
  record,
  preRefreshCallback,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [reason, setReason] = useState('');

  const handleSave = useCallback(() => {
    handleClose();
    return validateOnSubmit({
      values: { reason },
      schema,
      setErrors: setError,
    }).then(async () => {
      dispatch(
        alertActions.setTodoActions({
          data: { ...record, dismissedOn: new Date(), dismissedNote: reason },
          preRefreshCallback,
          message: 'Alert dismissed',
        }),
      );
    });
  }, [dispatch, reason, record, preRefreshCallback, handleClose]);

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title='Alert dismiss reason'
      onSave={handleSave}
    >
      <div style={{ margin: 24 }}>
        <TextInput
          fullWidth
          className={classes.inputRow}
          value={reason}
          name='reason'
          onChange={(e) => setReason(e.target.value)}
          label='Reason'
          multiline
          schema={schema}
          error={error.reason}
          required
          rows={3}
        />
      </div>
    </FormModal>
  );
}
