import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useStateWithCallbackInstant } from 'use-state-with-callback';
import makeStyles from '@mui/styles/makeStyles';
import {
  List,
  DataGrid,
  FunctionField,
  BooleanField,
} from '../../../components';
import { AlertSettingsForm } from '../../../components/modals/AlertSettingsForm';
import { EditIcon } from '../../../themes';
import { useGetUsersData } from '../../../hooks';
import { styles } from './sharedStyles';

const useStyles = makeStyles((theme) => ({ ...styles(theme) }));

export function AlertSettingsPage() {
  const classes = useStyles();
  const { companyID } = useGetUsersData();
  const [hoveringCellId, setHoveringCellId] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useStateWithCallbackInstant(null, (id) =>
    setOpenEdit(!!id),
  );

  const handleMouseEntering = useCallback((id, record) => {
    setHoveringCellId(id);
  }, []);

  const handleMouseLeave = useCallback((id, record) => {
    setHoveringCellId((curId) => (curId === id ? undefined : curId));
  }, []);

  if (!companyID) return null;

  return (
    <div style={{ paddingTop: 35 }}>
      <List
        baseUrl={`/companies/${companyID}/settings/alerts`}
        resource='alertSettings'
        // filter={{ Text: search }}
        bulkActionButtons={false}
        // rowClick={rowClick}
        // rowStyle={rowStyle}
        mouseEnter={handleMouseEntering}
        mouseLeave={handleMouseLeave}
        className={clsx(classes.list)}
        actions={<AlertActions />}
      >
        <DataGrid>
          <FunctionField
            source=''
            label='Alert'
            sortable={false}
            render={(record) => {
              const { name } = record;
              return name.replace(
                new RegExp('{LevelOfCareName}', 'g'),
                'Level Of Care',
              );
            }}
          />
          <FunctionField
            source=''
            label='User types'
            sortable={false}
            render={(record) => {
              const { alertUserTypes } = record;
              const userTypes = alertUserTypes.map((t) => t.userTypeDisplay);
              return userTypes.join(', ');
            }}
          />
          <BooleanField source='isActive' label='Active' sortable={false} />
          <BooleanField
            source='canDismiss'
            label='Can dismiss'
            sortable={false}
          />
          <BooleanField
            source='canSnooze'
            label='Can snooze'
            sortable={false}
          />
          <FunctionField
            source=''
            label=''
            align='left'
            sortable={false}
            cellStyle={{ maxWidth: 15 }}
            render={(record) => {
              const { id } = record;
              return id + '' === hoveringCellId ? (
                <EditIcon
                  onClick={() => setEditId(record.id)}
                  className={classes.editIcon}
                />
              ) : (
                <span />
              );
            }}
          />
        </DataGrid>
      </List>
      {openEdit && (
        <AlertSettingsForm
          open={openEdit}
          handleClose={() => setEditId(null)}
          id={editId}
          apiURL={`/companies/${companyID}/settings/alerts`}
        />
      )}
    </div>
  );
}

function AlertActions() {
  return null;
}
