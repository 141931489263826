import { resourceActions, uiActions } from '..';
import { RefreshKeys } from '../../lib';

export const alertActions = {
  setTodoActions({ data, preRefreshCallback, message }) {
    return async (dispatch) => {
      const { id, doneOn, snoozedUntil, dismissedNote, dismissedOn } = data;
      const payload = {
        todoID: id,
        doneOn,
        snoozedUntil,
        dismissedNote,
        dismissedOn,
      };
      const response = await dispatch(
        resourceActions.postResource({
          baseUrl: '/alerts/actions',
          payload,
          message,
        }),
      );
      if (response.data) {
        if (preRefreshCallback) {
          preRefreshCallback();
        }
        dispatch(
          uiActions.incrementViewVersion({
            viewName: RefreshKeys.Todos,
            caseId: data.caseID,
          }),
        );
      }
    };
  },
};
