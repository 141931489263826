import {
  Fragment,
  useEffect,
  useState,
  useCallback,
  Suspense,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { Toolbar, Divider } from '@mui/material';
import { ActionsTabs, SuspensePageLoader } from '../../../components';
import {
  useUpdatePageQueryParams,
  useGetUsersData,
  useGetQueryParams,
} from '../../../hooks';
import { uiActions } from '../../../state';
import { AlertSettingsPage } from './AlertSettingsPage';
import { CompanyPayersPage } from './CompanyPayersPage';
import CompanyTagsPage from './CompanyTagsPage';
import { GeneralSettingsPage } from './GeneralSettingsPage';
import { IntegrationSettingsPage } from './IntegrationSettingsPage';
import { useStyles } from './settings.styles';
import { ListSettingsPage } from './ListSettingsPage';
import { ApplicationTagsPage } from './ApplicationTagsPage';
import { SettingsPageViews, PermissionClaims } from '../../../lib';

export default function Settings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const updatePageQueryParams = useUpdatePageQueryParams();
  const { companyID, PermissionClaim = [] } = useGetUsersData();
  const { view } = useGetQueryParams();

  const allowedTabsForUser = useMemo(
    () =>
      allTabs.filter((t) =>
        t.permissionClaims.some((p) => PermissionClaim.includes(p)),
      ),
    [PermissionClaim],
  );
  const defaultTab = allowedTabsForUser[0];
  const currentTab =
    allowedTabsForUser.find((t) => t.value === view) || defaultTab;

  const [tabPosition, setTabPosition] = useState(currentTab?.value);

  const Component = currentTab?.component;

  useEffect(() => {
    dispatch(uiActions.setPageNameHeader('Settings'));
    return () => {
      dispatch(uiActions.setPageNameHeader(''));
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(uiActions.getCompanySettings(companyID));
    };
  }, [companyID, dispatch]);

  useEffect(() => {
    setTabPosition(currentTab?.value);
  }, [currentTab]);

  const onClick = useCallback(
    (_, position) => {
      updatePageQueryParams({ view: position }, true);
    },
    [updatePageQueryParams],
  );

  if (!allowedTabsForUser.length) {
    return null;
  }

  return (
    <Fragment>
      <Toolbar disableGutters={true}>
        <div className={classes.detailsActions}>
          <ActionsTabs
            onClick={onClick}
            tabs={allowedTabsForUser}
            tabPosition={tabPosition}
            style={{ marginLeft: -12 }}
          />
        </div>
      </Toolbar>
      <Divider className={classes.divider} />
      <Suspense fallback={<SuspensePageLoader />}>
        <Component />
      </Suspense>
    </Fragment>
  );
}

//Note: do not use this directly, must be filtered by permissionClaims
const allTabs = [
  {
    name: 'Alerts',
    value: SettingsPageViews.alert,
    component: AlertSettingsPage,
    permissionClaims: [PermissionClaims.CompanyEditClaim],
  },
  {
    name: 'General',
    value: SettingsPageViews.general,
    component: GeneralSettingsPage,
    permissionClaims: [PermissionClaims.CompanyEditClaim],
  },
  {
    name: 'Payers',
    value: SettingsPageViews.payers,
    component: CompanyPayersPage,
    permissionClaims: [PermissionClaims.CompanyEditClaim],
  },
  {
    name: 'Integrations',
    value: SettingsPageViews.integration,
    component: IntegrationSettingsPage,
    permissionClaims: [PermissionClaims.CompanyEditClaim],
  },
  {
    name: 'Tags',
    value: SettingsPageViews.tags,
    component: CompanyTagsPage,
    permissionClaims: [PermissionClaims.SuperAdminEditClaim],
  },
  {
    name: 'Lists',
    value: SettingsPageViews.lists,
    component: ListSettingsPage,
    permissionClaims: [PermissionClaims.CompanyListEditClaim],
  },
  {
    name: 'Application Labels',
    value: SettingsPageViews.applicationTags,
    component: ApplicationTagsPage,
    permissionClaims: [PermissionClaims.CompanyEditClaim],
  },
];

const SettingsPermissions = allTabs.reduce((distinctClaims, currTab) => {
  const isClaimInList = (claim) => distinctClaims.includes(claim);
  distinctClaims.push(
    ...currTab.permissionClaims.filter((c) => !isClaimInList(c)),
  );
  return distinctClaims;
}, []);

export function hasSettingsPermissions(PermissionClaim = []) {
  return SettingsPermissions.some((p) => PermissionClaim.includes(p));
}
