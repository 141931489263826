import { IconButton, Chip as MuiChip, Typography, Box } from '@mui/material';
import { useMatch } from 'react-router-dom';
import { PREVIEW_WIDTH, Routes, SIDEBAR_RIGHT_WIDTH } from '../../lib';
import { ArrowUpwardIcon, RefreshIcon } from '../../themes';
import { FlexCenter } from '../ui';
import { ColoredIcon } from '../common';

const CHIP_WIDTH = 188.49;
export function RefreshPrompt({
  handleRefresh,
  containerWidth = PREVIEW_WIDTH,
  paddingRight = 0, //spacing from the container to the right edge of the site OTHER THAN the SidebarRight
}) {
  const isCaseDetailsPage = useMatch(Routes.caseDetails.path);

  return (
    <Box
      marginTop={2}
      justifyContent={'center'}
      position={'fixed'}
      //center the chip within the container
      right={`calc( 
        ${containerWidth / 2}px
         - ${CHIP_WIDTH / 2}px
         + ${paddingRight}px
         + ${isCaseDetailsPage ? SIDEBAR_RIGHT_WIDTH : 0}px)`}
      zIndex={2}
    >
      <MuiChip
        variant='filled'
        label={
          <FlexCenter gap={1}>
            <ColoredIcon
              Icon={ArrowUpwardIcon}
              fontSize='small'
              iconColor='#FFF'
            />
            <Typography color={'#FFF'} pr={1}>
              New Activity
            </Typography>
            <IconButton size='medium' onClick={handleRefresh}>
              <ColoredIcon
                fontSize='small'
                iconColor='#FFF'
                Icon={RefreshIcon}
              />
            </IconButton>
          </FlexCenter>
        }
        sx={{
          backgroundColor: 'primary.main',
          boxShadow:
            '0 24px 32px 0 rgba(0,0,0,0.08), 0 24px 24px 0 rgba(0,0,0,0.12)',
        }}
      />
    </Box>
  );
}
