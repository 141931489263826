import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, TextField } from '@mui/material';
import { CopyOutlinedIcon } from '../../../themes';
import { settingActions, settingSelectors } from '../../../state';
import { ReadOnlyComponent } from '../../../components';
import { useStyles } from './IntegrationSettingsPage.styles';
import { systemPersistSelectors } from '../../../state';
import { useCopyTextToClipboard } from '../../../hooks';
import { IS_NOT_PROD } from '../../../lib';

export function IntegrationSettingsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const copyTextToClipboard = useCopyTextToClipboard();
  const integrationSettings = useSelector(settingSelectors.integrations);
  const webhookEventTypes = useSelector(
    systemPersistSelectors.webhookEventTypes,
  );
  const { isEnabled, clientID, clientSecret } = integrationSettings;
  const companyWebhooksUrl = useSelector(settingSelectors.companyWehbooksUrl);
  const companyWebhooksEvents = useSelector(
    settingSelectors.companyWebhookEvents,
  );
  const [webhookUrl, setWebhookUrl] = useState(companyWebhooksUrl);

  useEffect(() => {
    if (!integrationSettings.userID) {
      dispatch(settingActions.getIntegrationSettings());
    }
  }, [dispatch, integrationSettings.userID]);

  useEffect(() => {
    dispatch(settingActions.getCompanyWebhooks());
  }, [dispatch]);

  useEffect(() => {
    setWebhookUrl(companyWebhooksUrl);
  }, [companyWebhooksUrl]);

  return (
    <div className={classes.integrationSettingsPage}>
      <div className={classes.pageSection}>
        <div className={classes.pageSectionTitle}>
          <p>Enable API</p>
          <Switch
            checked={isEnabled}
            onChange={() =>
              dispatch(settingActions.postIntegrationSettings(!isEnabled))
            }
            name='checkedA'
            color='primary'
          />
        </div>
        {isEnabled && (
          <>
            <TextField
              variant='outlined'
              value={clientID}
              label='Client ID'
              InputProps={{
                endAdornment: (
                  <CopyOutlinedIcon
                    style={{ color: '#8990B6', cursor: 'pointer' }}
                    onClick={() => {
                      copyTextToClipboard(clientID);
                    }}
                  />
                ),
              }}
              style={{ width: 600, maxWidth: '100%', marginBottom: 32 }}
            />
            <div className={classes.flexContainer}>
              <TextField
                variant='outlined'
                value={clientSecret}
                label='Client Secret'
                InputProps={{
                  endAdornment: (
                    <CopyOutlinedIcon
                      style={{ color: '#8990B6', cursor: 'pointer' }}
                      onClick={() => {
                        copyTextToClipboard(clientSecret);
                      }}
                    />
                  ),
                }}
              />
              <p
                className={classes.linkText}
                onClick={() => dispatch(settingActions.rotateToken())}
                style={{ marginLeft: 24 }}
              >
                Generate new
              </p>
            </div>
          </>
        )}
      </div>
      <div className={classes.pageSection}>
        <div className={classes.pageSectionTitle}>
          <p>Webhooks</p>
        </div>
        <div
          style={{ marginBottom: 32, display: 'flex', alignItems: 'center' }}
        >
          <TextField
            label='URL'
            value={webhookUrl}
            onChange={(e) => setWebhookUrl(e.target.value)}
            variant='outlined'
            style={{ width: 328, maxWidth: '100%' }}
          />
          {companyWebhooksUrl !== webhookUrl && (
            <p
              className={classes.linkText}
              onClick={() =>
                dispatch(settingActions.postCompanyWebhooksURL(webhookUrl))
              }
              style={{ marginLeft: 24 }}
            >
              Save url
            </p>
          )}
        </div>
        <div className={classes.eventsTable}>
          <p className={classes.eventsTableTitle}>Event</p>
          {webhookEventTypes.map((event) => {
            const activeEvent = companyWebhooksEvents?.some(
              (companyEvent) =>
                companyEvent.event === event.id && companyEvent.isActive,
            );
            return (
              <div key={event.id}>
                <div className={classes.eventsTableRow}>
                  <p className={classes.eventsTableRowTitle}>{event.name}</p>
                  <ReadOnlyComponent
                    message='Events are disabled until a webhook url is set'
                    isReadOnly={!companyWebhooksUrl}
                    placement='left'
                  >
                    <Switch
                      checked={activeEvent}
                      onChange={() =>
                        dispatch(
                          settingActions.postCompanyWebhooksEvent(
                            event,
                            !activeEvent,
                          ),
                        )
                      }
                      name='checkedA'
                      color='primary'
                    />
                  </ReadOnlyComponent>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.pageSection}>
        <div className={classes.pageSectionTitle}>
          <p>Documentation</p>
        </div>
        <a
          href={
            IS_NOT_PROD
              ? 'https://staging-dev.aidace.net/'
              : 'https://developer.aidace.net/'
          }
          target='_blank'
          rel='noreferrer'
          className={classes.linkText}
        >
          View full documentation
        </a>
      </div>
    </div>
  );
}
