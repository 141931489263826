import { createSelector } from '@reduxjs/toolkit';
import { State } from '../types';
import {
  CaseIdType,
  CaseNavigationSourceType,
  NoCaseId,
  RefreshKeyType,
  WorkflowSetting,
} from '../../lib';

const emptyArray: any[] = [];
const emptyObject = {};
export const uiSelectors = {
  loading: (state: State) => state.ui.loading,
  isSidebarOpen: (state: State) => state.ui.isSidebarOpen,
  isOverlayOpen: (state: State) => state.ui.isOverlayOpen,
  isBoardPreviewOpen: (state: State) => state.ui.isBoardPreviewOpen,
  isListPreviewOpen: (state: State) => state.ui.isListPreviewOpen,
  isPreviewOpen: (state: State) =>
    state.ui.isBoardPreviewOpen || state.ui.isListPreviewOpen,
  previewId: (state: State) => state.ui.previewId,
  selectedRowId: (state: State) => state.ui.selectedRowId,
  listLoading: (state: State) => state.ui.listLoading,
  globalViewVersion: (state: State) => state.ui.globalViewVersion,
  viewVersion: createSelector(
    [
      (state: State) => state.ui.viewVersions,
      (_: State, viewName: RefreshKeyType) => viewName,
      (_: State, __: RefreshKeyType, caseId?: CaseIdType) => caseId || NoCaseId,
    ],
    (
      viewVersions: Record<RefreshKeyType, Record<CaseIdType, number>>,
      viewName: RefreshKeyType,
      caseId: CaseIdType,
    ) => (viewVersions[viewName] ? viewVersions[viewName][caseId] : 0) ?? 0,
  ),
  notification: (state: State) => state.ui.notification,
  pageNameHeader: (state: State) => state.ui.pageNameHeader,
  tabbedHeader: (state: State) => state.ui.tabbedHeader,
  companySettings: (state: State) => state.ui.companySettings?.companySettings,
  workflowSettings: (state: State): WorkflowSetting[] =>
    state.ui.companySettings?.workflowSettings,
  counties: (state: State) => state.ui.counties || emptyArray,
  outsourceContacts: (state: State) => state.ui.outsourceContacts || emptyArray,
  medicaidCaseworkers: (state: State) =>
    state.ui.medicaidCaseworkers || emptyArray,
  judges: (state: State) => state.ui.judges || emptyArray,
  insuranceCaseworkers: (state: State) =>
    state.ui.insuranceCaseworkers || emptyArray,
  insuranceCompanies: (state: State) =>
    state.ui.insuranceCompanies || emptyArray,
  bankAccounts: (state: State) => state.ui.bankAccounts || emptyArray,
  potentialCompanyUsers: (state: State) =>
    state.ui.potentialCompanyUsers || emptyArray,
  companyUsers: (state: State) => state.ui.companyUsers || emptyArray,
  listViews: (state: State) => state.ui.listViews,
  companyListViews: (state: State) =>
    state.ui.companyListViews.data || emptyArray,
  companyListViewsLoading: (state: State) => state.ui.companyListViews.loading,
  hasNoCompanyListViews: (state: State) =>
    state.ui.companyListViews.hasNoCompanyListViews,
  quickAction: (state: State) => state.ui.quickAction || emptyObject,
  todosCount: (state: State) => state.ui.todosCount,
  currentListFilter: (state: State) => state.ui.currentListFilter,
  showAddToEMR: (state: State) => state.ui.showAddToEMR,

  isAnnouncementLogOpen: (state: State) => state.ui.isAnnouncementLogOpen,
  currentAnnouncement: (state: State) =>
    state.ui.currentAnnouncement || emptyObject,
  refreshActivityFeed: (state: State) => state.ui.refreshActivityFeed,
  isNewDashboardOn: (state: State) => state.ui.isNewDashboardOn,
  isNewCaseDetailsOn: (state: State) => state.ui.isNewCaseDetailsOn,
  applicationTagOptions: (state: State) =>
    state.ui.applicationTagOptions || emptyArray,
  caseDetailsSourcePage: (state: State): CaseNavigationSourceType | null =>
    state.ui.caseDetailsSourcePage,
  dashboardQueryParams: (state: State) => state.ui.dashboardQueryParams,
};
